@import "variables";
@import "./versions/2.0.7/base/scss/meetingSubscriptionFlowVariant1";
@import "./versions/2.0.7/base/scss/meetingVariant1";
@import "./versions/2.0.7/base/scss/datepicker";
// Theme - only 1 entry possible!!
@import "./versions/2.0.7/#themes/neptunus";

// inject:defaultWidgets:scss
@import "./versions/2.0.7/defaultWidgets/accordionVariant1/scss/_accordionVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/addCommentVariant1/scss/_addCommentsVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/addFunctionVariant1/scss/_addFunctionVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/alertVariant1/scss/_alertVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/allWorkgroupMeetingsVariant1/scss/_allWorkgroupMeetingsVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/bannerVariant1/scss/_bannerVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/changeAttributesVariant1/scss/_changeAttributesVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/changeFunctionVariant1/scss/_changeFunctionVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/changePasswordVariant1/scss/_changePasswordVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/changePersonalInformationVariant1/scss/_changePersonInformationVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/changeUsernameVariant1/scss/_changeUsernameVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/commentsVariant1/scss/_commentsVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/cookieVariant1/scss/_cookieVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/documentsVariant1/scss/_documentsVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/cookieSettingsVariant1/scss/_cookieSettingsVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/documentDetailVariant1/scss/_documentDetailVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/facebookVariant2/scss/_facebookVariant2.scss";
@import "./versions/2.0.7/defaultWidgets/footerVariant1/scss/_footerVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/footerVariant2/scss/_footerVariant2.scss";
@import "./versions/2.0.7/defaultWidgets/footerVariant3/scss/_footerVariant3.scss";
@import "./versions/2.0.7/defaultWidgets/footerVariant4/scss/_footerVariant4.scss";
@import "./versions/2.0.7/defaultWidgets/formVariant1/scss/_formVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/formV3Variant1/scss/_formV3Variant1.scss";
@import "./versions/2.0.7/defaultWidgets/galleryVariant1/scss/_galleryVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/headerVariant1/scss/_headerVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/headerVariant2/scss/_headerVariant2.scss";
@import "./versions/2.0.7/defaultWidgets/highlightVariant1/scss/_highlightVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/loginVariant1/scss/_loginVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/meetingAddToCalendarVariant1/scss/_meetingAddToCalendarVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/meetingsSmallVariant1/scss/_meetingsSmallVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/meetingsSmallVariant3/scss/_meetingsSmallVariant3.scss";
@import "./versions/2.0.7/defaultWidgets/meetingsVariant1/scss/_meetingsVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/memberSearchVariant1/scss/_memberSearchVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/myAttributesVariant2/scss/_myAttributesVariant2.scss";
@import "./versions/2.0.7/defaultWidgets/myNewslettersVariant1/scss/_myNewslettersVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/myNewslettersVariant2/scss/_myNewslettersVariant2.scss";
@import "./versions/2.0.7/defaultWidgets/myPersonalInformationVariant1/scss/_myPersonalInformationVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/mySubscribedMeetingsVariant1/scss/_mySubscribedMeetingsVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/navigationPagesVariant1/scss/_navigationPagesVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/navigationVariant1/scss/_navigationVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/newslettersVariant2/scss/_newslettersVariant2.scss";
@import "./versions/2.0.7/defaultWidgets/pagesSmallVariant1/scss/_pagesSmallVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/pagesVariant1/scss/_pagesVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/pagesVariant2/scss/_pagesVariant2.scss";
@import "./versions/2.0.7/defaultWidgets/pagesVariant3/scss/_pagesVariant3.scss";
@import "./versions/2.0.7/defaultWidgets/pagesVariant4/scss/_pagesVariant4.scss";
@import "./versions/2.0.7/defaultWidgets/pagesVariant5/scss/_pagesVariant5.scss";
@import "./versions/2.0.7/defaultWidgets/pagesVariant6/scss/_pagesVariant6.scss";
@import "./versions/2.0.7/defaultWidgets/pagesVariant7/scss/_pagesVariant7.scss";
@import "./versions/2.0.7/defaultWidgets/pagesWithFilterVariant1/scss/_pagesWithFilterVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/parallaxVariant1/scss/_parallaxVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/personAttributesVariant2/scss/_personAttributesVariant2.scss";
@import "./versions/2.0.7/defaultWidgets/personNameVariant1/scss/_personNameVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/personPersonalInformationVariant1/scss/_personPersonalInformationVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/personWorkgroupsVariant2/scss/_personWorkgroupsVariant2.scss";
@import "./versions/2.0.7/defaultWidgets/personsVariant1/scss/_personsVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/pollVariant1/scss/_pollVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/removeFunctionVariant1/scss/_removeFunctionVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/resetPasswordVariant1/scss/_resetPasswordVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/searchVariant1/scss/_searchVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/sidebarVariant1/scss/_sidebarVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/sliderVariant1/scss/_sliderVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/sliderVariant2/scss/_sliderVariant2.scss";
@import "./versions/2.0.7/defaultWidgets/subscribeNewsletterVariant1/scss/_subscribeNewsletterVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/textVariant1/scss/_textVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/twitterVariant1/scss/_twitterVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/twitterVariant2/scss/_twitterVariant2.scss";
@import "./versions/2.0.7/defaultWidgets/videoVariant1/scss/_videoVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/welcomeVariant1/scss/_welcomeVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/welcomeVariant2/scss/_welcomeVariant2.scss";
@import "./versions/2.0.7/defaultWidgets/workgroupDocumentsVariant1/scss/_workgroupDocumentsVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/workgroupMeetingDetailVariant1/scss/_workgroupMeetingDetailVariant1.scss";
@import "./versions/2.0.7/defaultWidgets/workgroupsVariant2/scss/_workgroupsVariant2.scss";
// endinject:defaultWidgets

// inject:specialWidgets:scss
@import "./versions/2.0.7/specialWidgets/headerVariant3/scss/_headerVariant3.scss";
@import "./versions/2.0.7/specialWidgets/logoBarVariant1/scss/_logoBarVariant1.scss";
@import "./versions/2.0.7/specialWidgets/sliderVariant3/scss/_sliderVariant3.scss";
@import "./versions/2.0.7/specialWidgets/tabsVariant1/scss/_tabsVariant1.scss";
// endinject:specialWidgets

// inject:kollaWidgets:scss
// endinject:kollaWidgets
